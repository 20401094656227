import '@angular/localize/init';

import { setRemoteDefinitions } from '@nx/angular/mf';
import { environment } from './environments/environment';
const buildTime = '1715097341365';
const prevBuildTime = localStorage.getItem('buildTime');
if (prevBuildTime) {
  if (buildTime !== prevBuildTime) {
    localStorage.clear();
    localStorage.setItem('buildTime', buildTime);
  }
} else {
  localStorage.clear();
  localStorage.setItem('buildTime', buildTime);
}
fetch('./assets/module-federation.manifest.json')
  .then((res) => res.json())
  .then((data) => updateMFRemotes(data))
  .then((definitions) => setRemoteDefinitions(definitions))
  .then(() => import('./bootstrap').catch((err) => console.error(err)));

const updateMFRemotes = (mfManifest: any) => {
  mfManifest['om'] = environment.OM_UI_URL;
  mfManifest['pm'] = environment.PM_UI_URL;
  mfManifest['copilot'] = environment.COPILOT_UI_URL;
  mfManifest['collateral'] = environment.COLLATERAL_UI_URL;
  return mfManifest;
};
